import './global.css';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import React, { useEffect } from 'react';
import { socket } from './socket';
import {
  BrowserRouter as Router, Route, Switch, useHistory, useLocation, withRouter
} from 'react-router-dom';

import { Box, createTheme, Fab, Toolbar, TextField, Typography, useMediaQuery, Grid, Button, Dialog, Slide } from '@material-ui/core';
import { createStyles, makeStyles, Theme, ThemeProvider, useTheme } from '@material-ui/core/styles';
import { closeOverlayAndReset as closeOverlayAndResetCreateView } from './redux/UIOverlayMeasurementCreateViewRedux';
import CommentIcon from '@material-ui/icons/Comment';
import { TransitionProps } from '@material-ui/core/transitions';

import { Player } from '@lottiefiles/react-lottie-player';
import axios, { AxiosRequestConfig } from 'axios';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import NavBar from './components/NavBar';
import OverlayViewAccount from './components/OverlayViewAccount';
import OverlayViewAddress from './components/OverlayViewAddress';
import OverlayViewMeasurementClothingEdit from './components/OverlayViewMeasurementClothingEdit';
import OverlayViewMeasurementClothingView from './components/OverlayViewMeasurementClothingView';
import OverlayViewMeasurementSelection from './components/OverlayViewMeasurementSelection';
import OverlayViewMeasurementInstantSizeTeam from './components/OverlayViewMeasurementInstantSizeTeam';
import OverlayViewMeasurementCreateNew from './components/OverlayViewMeasurementCreateNew';
import OverlayViewMeasurementCreateNewRatings from './components/OverlayViewMeasurementCreateNewRatings';
import OverlayViewUniversalSizeFlow from './components/OverlayViewUniversalSize10Flow';
import OverlayViewOfferSignUp from './components/OverlayViewOfferSignUp';
import OverlayViewPersonalSize from './components/OverlayViewPersonalSize';
import OverlayViewPersonalSizePromo from './components/OverlayViewPersonalSizePromo';
import OverlayViewPopupFlowVersion from './components/OverlayViewPopupFlowVersion';
import OverlayViewPopupOngkir from './components/OverlayViewPopupOngkir';
import OverlayViewPopupMarketplaceStart from './components/OverlayViewPopupMarketplaceStart';
import OverlayViewPopupRepurchase from './components/OverlayViewPopupRepurchase';
import OverlayViewPopupInstantSizeTeam from './components/OverlayViewPopupInstantSizeTeam';
import OverlayViewPopupQRResult from './components/OverlayViewPopupQRResult';
import OverlayViewMeasurementMethodSelection from './components/OverlayViewMeasurementMethodSelection';
import OverlayViewPopupMeasurementKit from './components/OverlayViewPopupMeasurementKit'
import OverlayViewUniversalSizeCreateMeasFlow from './components/OverlayViewUniversalSizeCreateMeas';
import { openOverlay as openOverlayIST} from './redux/UIOverlayMeasurementInstantSizeTeamViewRedux';
import PageAccount from './components/PageAccount';
import PageCareers from './components/PageCareers';
import PageCheckout from './components/PageCheckout';
import PageCreatePersonalSizeFromItemQR from './components/PageCreatePersonalSizeFromItemQR';
import PageCreatePersonalSizeMeasKit from './components/PageCreatePersonalSizeMeasKit';
import PageCreateRepurchaseFlow from './components/PageCreateRepurchaseFlow';
import PageCreateReferralFlow from './components/PageCreateReferralFlow';
import PageHome from './components/PageHome';
import PageHomeFooter from './components/PageHome7Footer';
import PageOrder from './components/PageOrder';
import PageTailorChat from './components/PageTailorChat';
import PageProduct from './components/PageProduct';
import LiveStream from './components/LiveStream';
import { Mixpanel } from './mixpanel';
import { AccountState, getChatHistory, updateState, newChatMessage } from './redux/AccountRedux';
import { fetchAddresses } from './redux/AddressRedux';
import { getCurrentShipmentPromo, postCheckCurrentCartAvailability, setProductSpotlightList } from './redux/CartRedux';
import { uploadLocalUnsavedMeasAndSynchronize } from './redux/MeasurementsRedux';
import { fetchOrdersList } from './redux/OrdersListRedux';
import { fetchProductsList } from './redux/ProductsListRedux';
import {
  FlowVersionState, loadFlowVersionState, setCollectionQueryKey, setFlowVersion, setSortQueryKey
} from './redux/UIOverlayFlowVersionRedux';
import { openOverlay } from './redux/UIOverlayOngkirRedux';
import { openOverlay as openOverlayPersonalSize, openOverlayPromo } from './redux/UIOverlayPersonalSizeRedux';
import { useAppDispatch, useAppSelector } from './reduxhooks';
import { sendWebsiteEventMessage, sendWebsiteLivestreamMessage, setUserIdentifier } from './telegrambotevents';
import { openOverlay as openOverlayInstantSizeTeam } from './redux/UIOverlayInstantSizeTeamRedux';
import { openOverlay as openOverlayMarketplaceStart } from './redux/UIOverlayMktplcStartRedux';
import { openOverlay as openOverlayRepurchase } from './redux/UIOverlayQRRepurchaseRedux';

import {
  Stage,
  LocalStageStream,
  SubscribeType,
  StageEvents,
  ConnectionState,
  StageConnectionState,
  StreamType
} from 'amazon-ivs-web-broadcast';
import Draggable from 'react-draggable'; // Import react-draggable
import Countdown from 'react-countdown';
import { ProductSpotlight } from 'common-ts/dist/models/Cart';
import { fetchCategoryReviewRecap } from './redux/CategoryReviewRedux';
import PageMailingPreference from './components/PageMailingPreference';

const theme = createTheme({
  palette: {
    primary: {
      light: '#e2f1f8',
      main: '#484848',
      dark: '#808e95',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'OakesGrotesk',
      'sans-serif'
    ].join(','),
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentRoot: {
      // margin: '20px',
      backgroundColor: '#fff',
      height: '100%',
    },
    overlayView: {
    },
    chatIconTailor: {
      width: '24vw',
      zIndex: 1300,
      position: 'fixed',
      left: '30px',
      bottom: '-4px',
      '-webkit-tap-highlight-color': 'transparent',
      [theme.breakpoints.down('xs')]: {
        left: '12px',
        width: '76vw',
        bottom: '-4px',
      }
    },
    chatIcon: {
      width: '8vw',
      zIndex: 1300,
      position: 'fixed',
      right: '0px',
      bottom: '0px',
      '-webkit-tap-highlight-color': 'transparent',
      [theme.breakpoints.down('xs')]: {
        right: '1vw',
        bottom: '1vw',
        width: '21vw',
      },
    },
    promoIcon: {
      width: '8vw',
      zIndex: 1300,
      position: 'fixed',
      left: '13px',
      bottom: '20px',
      '-webkit-tap-highlight-color': 'transparent',
      [theme.breakpoints.down('xs')]: {
        left: '3vw',
        bottom: '5vw',
        width: '21vw',
      },
    },
    promoIconSvg: {
      '-webkit-tap-highlight-color': 'transparent',
      paddingLeft: '0px',
      width: '100%',
      marginBottom: '0px'
    },
    personalSizeIconSvgLocked: {
      width: '87%',
      paddingLeft: '4px',
      paddingBottom: "10px",
      '-webkit-tap-highlight-color': 'transparent',
    },
    personalSizeIconSvg: {
      width: '84%',
      paddingLeft: '7px',
      paddingBottom: "10px",
      '-webkit-tap-highlight-color': 'transparent',
      cursor: 'pointer',
    },
    chatIconSvgTailor: {
      width: '80%',
      paddingLeft: '10px',
      marginBottom: '0',
      '-webkit-tap-highlight-color': 'transparent',
      borderRadius: '7% 7% 0 0',
    },
    chatIconSvg: {
      width: '95%',
      paddingLeft: '10px',
      marginBottom: '-16px',
      '-webkit-tap-highlight-color': 'transparent',
    },
    chatIconSpacer: {
      width: '24vw',
      minHeight: '28vw', // Estimated height needed
      [theme.breakpoints.down('xs')]: {
        width: '50vw',
        minHeight: '80vw', // Estimated height needed
      }
    },
    commentIcon: {
      marginRight: '10px',
    },
    bannerOngkir: {
      width: "100%",
      // marginLeft: "-6px",
      minHeight: "36px",
      backgroundColor: "#2A2A2A",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    bannerOngkirText: {
      color: "#ffffff",
      fontSize: "9px",
      height: "9px",
      letterSpacing: "1px",
      fontWeight: 300,
    },
    bannerOngkirTextLink: {
      paddingLeft: "4px",
      textUnderlineOffset: "3px",
      textDecorationThickness: "0.8px",
      textDecorationLine: "underline",
      cursor: "pointer",
      WebkitTapHighlightColor: "transparent",
    },
    dialogSendMessageLivestream: {
      '& .MuiDialog-container': {
        overflowY: 'scroll',
      },
      '& .MuiPaper-root': {
        borderRadius: '31px',
        maxHeight: '2000px',
        marginTop: '320px',
        marginBottom: '80px',
      }
    },
  }),
);

function ScrollToTop({ history }: any) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (window.location.href.includes("/shop")) {
        // setTimeout(() => {
        //   window.scrollTo({ top: 0, behavior: 'smooth' });
        // }, 350); 
        // Give 0.35s delay, for rendering to take place
      } else {
        // window.scrollTo({ top: 0, behavior: 'smooth'});
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 350); 
        // Give 0.35s delay, for rendering to take place
      }
    });
    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

const ScrollToTopComponent = withRouter(ScrollToTop);

export function BantuanSpacer() {
  const classes = useStyles();
  return (
    <Box className={classes.chatIconSpacer}>
    </Box>
  )
}

interface BantuanProps {
  hideBantuan: boolean
  useTailor: boolean
  showPersonalSizeProfile: boolean
  openPersonalSizeOverlay: () => void
}

export function Bantuan({ useTailor, hideBantuan, showPersonalSizeProfile, openPersonalSizeOverlay }: BantuanProps) {
  const theme = useTheme();
  const productDetail = useAppSelector(state => state.overlayMeasurementCreateView.productDetail)
  const userIsSignedInAndHasPurchased = useAppSelector(state => state.account.authState === 'signedin' && state.ordersList.paidOrderCount >= 1);
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [mktplcClickCounter, setMktplcClickCounter] = React.useState(0);

  if (hideBantuan) {
    return null;
  }

  return (
    <Box className={useTailor ? classes.chatIconTailor : classes.chatIcon}
      onClick={() => {
        if ((window as any).fbq !== null) {
          (window as any).fbq('track', 'Contact');
        }
        Mixpanel.track('contactFarEastWhatsapp');
      }} key={useTailor ? "tailor-box-thumbnail" : "wa-box-thumbnail"}>
      {
        useTailor === false && userIsSignedInAndHasPurchased === false && 
        (
          (window.location.href + "")?.includes("/shop") && 
          !(window.location.href + "")?.includes("/product") && 
          !(window.location.href + "")?.includes("/category/batik") // Hide batik category
        ) &&
        mktplcClickCounter < 1 ? 
        <div
          onClick={() => {
            console.log("Opening mktplc measkit promo");
            (window as any).hj('event', 'start_hotjar_rec'); // START HOTJAR REC
            // sendWebsiteLivestreamMessage('[HOTJAR REC - UX] STARTED - MKTPLC POPUP');
            dispatch(openOverlayMarketplaceStart({
              chosenKey: 'intro',
              chosen: mktplcClickCounter + ''
            }));
            sendWebsiteEventMessage("User buka popup PROMO mktplc measkit");
            setMktplcClickCounter((value) => value + 1);
          }}>
          <img 
          key={'start-with-mktplc'}
          src={process.env.PUBLIC_URL + '/assets/icon-floating-mktplc.png'} alt={"personalsize-thumbnail"}
          className={classes.personalSizeIconSvg} />
        </div>:
        null
      }
      <a
        href={useTailor ? 
          "#" :
          "https://wa.me/+6287813028340?text=Halo%20kak,%20apa%20bisa%20pesan%20via%20WA?"}>
        <img src={
          useTailor ?
            "https://studiosclo.uk/general/konsultasi-size-instant-size-team-2.jpg" :
            "https://studiosclo.uk/general/bubble-mau-pesan-wa-logo-2.png"
        } alt={useTailor ? "tailor-thumbnail" : "wa-thumbnail"}
        onClick={ useTailor ?
          () => {
            dispatch(dispatch(openOverlayIST(productDetail)));
            dispatch(closeOverlayAndResetCreateView())
            sendWebsiteEventMessage("User buka tombol konsultasi ukuran");

            setTimeout(() => {
              dispatch(openOverlayInstantSizeTeam());
            }, 300);
          }:
          () => {
            sendWebsiteEventMessage("User buka tombol WA");
          }
        } className={useTailor ? classes.chatIconSvgTailor : classes.chatIconSvg} />
      </a>
    </Box>
  )
}

const countdownRendererPromoIcon = (color: string) => {
  return (({ hours, minutes, seconds, completed }: any) => {
    // const classes = useStyles();

    if (completed) {
      return (
      <div/>);
    } else {
      return (
      <Box style={{
        containerType: 'inline-size',
        margin: '-36cqw 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        color: color,
        width: '100%',
        textAlign: 'center',
      }}>
        <Typography style={{
          fontSize: '14cqw',
          fontFamily: 'OakesGrotesk',
          fontWeight: 500,
        }}>{String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</Typography>
      </Box>)
    }
  })
};


interface PromoFloatProps {
}

export function PromoFloat({}: PromoFloatProps) {
  const theme = useTheme();
  const couponObj = useAppSelector(state => state.cart.coupon);
  // const userIsSignedInAndHasPurchasedOnce = useAppSelector(state => state.account.authState === 'signedin' && state.ordersList.paidOrderCount === 1);
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();
  const dispatch = useAppDispatch();

  if ((couponObj?.couponKey?.startsWith("REPURCHASE") || couponObj?.couponKey?.startsWith("AJAKTEMEN")) && new Date().toISOString() < couponObj.expirationDateTime) {

    return (
      <Box className={classes.promoIcon}
        onClick={() => {
          dispatch(openOverlayRepurchase());
        }} key={"promo-box-thumbnail"}>
        <Box style={{
          position: 'relative',
          width: '100%',
          containerType: 'inline-size',
        }}>
        <img src={couponObj?.couponKey?.startsWith("REPURCHASE") ? 
        "https://studiosclo.uk/general/icon-promo-20-countdown-red.png" : 
        "https://studiosclo.uk/general/icon-promo-20-countdown.png"} alt={"promo-thumbnail"}
        className={classes.promoIconSvg}/>
        <Countdown
          date={couponObj.expirationDateTime}
          renderer={countdownRendererPromoIcon(couponObj?.couponKey?.startsWith("REPURCHASE") ? '#ffffff' : '#121212')}
          onComplete={(timeDelta, completedOnStart) => {
          }}
        />
        </Box>
      </Box>
    )
  }

  return null;
}

export function BannerOngkir({ hideView }: { hideView?: boolean }) {
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const currentShipmentPromo = getCurrentShipmentPromo();

  return (
    <Box className={classes.bannerOngkir} style={{visibility: hideView ? 'hidden' : 'visible'}}>
      <Typography className={classes.bannerOngkirText}>
        DAPATKAN FREE ONGKIR S/D {currentShipmentPromo.tanggalAkhir
          + " " + currentShipmentPromo.bulan.toUpperCase()
          + " " + currentShipmentPromo.tahun}
        <a className={classes.bannerOngkirTextLink} onClick={() => {
          dispatch(openOverlay());
          sendWebsiteEventMessage("User opened ongkir popup");
        }}>LIHAT S&K</a>
      </Typography>
    </Box>
  )
}


const imageLottieDict: {[key: string]: string} = {
  "konsultasiSize": "https://studiosclo.uk/general/Lottie_Jahit.json",
  "dokterKain": "https://studiosclo.uk/general/Lottie_Delivery.json",
};
const imageLottieDictStyle: {[key: string]: any} = {
  "konsultasiSize": {
    height: '110px',
    position: "absolute",
    bottom: '-6px',
    left: 'calc(50% - 56px)',
  },
  "konsultasiSizeMobile": {
    height: '16vw', // 25h to 13w
    position: "absolute",
    bottom: '2px',
    left: 'calc(50% - 9vw)',
  },
  "konsultasiSizeMobileFull": {
    height: '14vw', // 25h to 13w
    position: "absolute",
    bottom: '2px',
    left: 'calc(50% - 9vw)',
  },
  "dokterKain": {
    height: '130px',
    position: "absolute",
    bottom: '-16px',
    left: 'calc(50% - 64px)',
  },
  "dokterKainMobile": {
    height: '20vw', // 25h to 13w
    position: "absolute",
    bottom: '-8px',
    left: 'calc(50% - 11vw)',
  },
  "dokterKainMobileFull": {
    height: '18vw', // 25h to 13w
    position: "absolute",
    bottom: '-8px',
    left: 'calc(50% - 11vw)',
  },
};
const imageLottieTitle: {[key: string]: string} = {
  "konsultasiSize": "KONSULTASI SIZE",
  "dokterKain": "DOKTER KAIN",
};
const imageLottieDescription: {[key: string]: string} = {
  "konsultasiSize": "Konsultasi, dan kita buatkan size, live! Bisa langsung dipakai dalam website.",
  "dokterKain": "Katanya kalau kain polyester itu pasti selalu jelek... apakah benar? Kita bahas.",
};

export const videoMainStreamId = 'mainstream';


// Small Stream Popup
const FloatingLiveStream = ({ streamIsOn, lsDialogSendMessageOpen, openSendMessage, wsConnection, stageInfo }: 
  { streamIsOn: boolean, lsDialogSendMessageOpen: boolean, openSendMessage: () => void, wsConnection: any, stageInfo: any }) => {
  const [kirimText, setKirimText] = React.useState('');
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [isMuted, setIsMuted] = React.useState<boolean>(true);

  return (
    <Draggable cancel="#mute-button-livestream, #tanya-live-container, #remote-media">
      <div style={{
        display: streamIsOn && !lsDialogSendMessageOpen ? 'block' : 'none',
        position: 'fixed',
        bottom: '20px',
        left: '20px',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: isMdOrUp ? '30px' : '21px',
        padding: isMdOrUp ? '12px' : '5px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
        zIndex: 1200, /* Adjust the z-index to ensure it appears above other content */
      }}
      >
        {/* {
          stageInfo?.event === "dokterKain" || stageInfo?.event === "konsultasiSize" ?
          <Box style={{
            height: '18px',
            position: 'relative',
          }}>
            <Player
              key='regular-animation'
              autoplay
              loop
              src={imageLottieDict[stageInfo.event as string]}
              style={imageLottieDictStyle[(stageInfo.event as string) + (isMdOrUp ? "" : "Mobile")]}
            />
          </Box>:
          null
        } */}
        <div id='remote-media-container' style={{
          position: 'relative',
          minWidth: '90px',
          minHeight: '100px',
        }}
        >
          {
            isMuted ? 
            <div style={{
              position: "absolute",
              top: "6px",
              right: "6px",
              color: "#ffffff",
              backgroundColor: "#232323",
              padding: "6px 4px 4px 6px",
              width: '20px',
              height: '20px',
              borderRadius: "18px",
              zIndex: 1000,
            }}
            id='mute-button-livestream'
            onClick={() => {
              setIsMuted(false);
              const videoEl: any = document.getElementById(videoMainStreamId);
              if (videoEl) {
                videoEl.muted = false;
              }
            }}>
              <VolumeOffIcon style={{
                fontSize: '17px'
              }}/>
            </div>:
            <div style={{
              position: "absolute",
              top: "6px",
              right: "6px",
              color: "#ffffff",
              backgroundColor: "#232323",
              padding: "6px 4px 4px 6px",
              width: '20px',
              height: '20px',
              borderRadius: "18px",
              zIndex: 1000,
            }}
            id='mute-button-livestream'
            onClick={() => {
              setIsMuted(true);
              const videoEl: any = document.getElementById(videoMainStreamId);
              if (videoEl) {
                videoEl.muted = false;
              }
            }}>
              <VolumeUpIcon style={{
                fontSize: '17px'
              }}/>
            </div>
          }
          <div id='remote-media' style={{
            position: 'relative',
          }}
          onClick={() => {
            setIsMuted(false);
            const videoEl: any = document.getElementById(videoMainStreamId);
            if (videoEl) {
              videoEl.muted = false;
            }
  
            openSendMessage();
          }} >
            <div id={videoMainStreamId + '-container'}>
              <video id={videoMainStreamId} style={{width: '100%', borderRadius: '18px', maxWidth: '30vw', maxHeight: '50vh'}} autoPlay muted playsInline/>
            </div>
          </div>
          {/* <div style={{
            position: "absolute",
            bottom: "6px",
            left: "6px",
            color: "#ffffff",
            backgroundColor: "#232323",
            padding: "6px 4px 4px 6px",
            width: '20px',
            height: '20px',
            borderRadius: "18px",
            zIndex: 1000,
          }}
          id='mute-button-livestream'
          onClick={() => {
            setIsMuted(false);
            const videoEl: any = document.getElementById(videoMainStreamId);
            videoEl.muted = false;
          }}>
            <Typography>{Object.values(lsParticipantsDict).filter(item => item === true).length}</Typography>
          </div> */}
        </div>
        {
          isMdOrUp ?
          <Grid container style={{
            maxWidth: '28vh', // Pairs to maximum height of 9 / 16 of 50vh (50vh * 9 / 16)
            padding: '4px 2px 7px 8px',
          }}>
            <Grid item xs={9}>
              <TextField
                margin="dense"
                fullWidth
                id="livestreamChatInput"
                name="livestreamChatInput"
                label="Tanya Disini"
                type="text"
                value={kirimText}
                error={false}
                onChange={(e) => {
                  setKirimText(e.target?.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}/>
            </Grid>
            <Grid item xs={3} style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              borderRadius: '20px',
            }}>
              <Button onClick={() => {
                if (kirimText !== '') {
                  // sendWebsiteLivestreamMessage('[' + participantId + '] ' + kirimText);
                  if (wsConnection) {
                    wsConnection.send(JSON.stringify({
                      "Action": "SEND_MESSAGE",
                      "Content": kirimText,
                    }));
                  }
                }
                setKirimText('');
              }} style={{
                backgroundColor: '#232323',
                color: '#ffffff',
                fontWeight: 400,
                letterSpacing: '0.9px',
                fontSize: '10px',
                padding: '4px 9px 1px 9px',
                borderRadius: '12px',
                minWidth: '4px',
              }}>
                KIRIM
              </Button>
            </Grid>
          </Grid>:
          <Grid container onClick={() => {
            setIsMuted(false);
            const videoEl: any = document.getElementById(videoMainStreamId);
            if (videoEl) {
              videoEl.muted = false;
            }

            openSendMessage();
          }} id='tanya-live-container'>
            <Grid item xs={6} style={{
              position: 'relative',
            }}>
              <Typography style={{
                fontSize: '8px',
                fontWeight: 600,
                letterSpacing: '0.6px',
                margin: '6px 4px 4px 5px',
                padding: '3px 3px 1px 8px',
                borderRadius: '10px',
                backgroundColor: '#232323',
                color: '#ffffff',
                textAlign: 'left',
              }}>
                LIVE
              </Typography>
              <div style={{
                position: 'absolute',
                right: '9px',
                top: '9.7px',
                borderRadius: '10px',
                width: '8px',
                height: '8px',
                color: 'red',
                backgroundColor: 'red',
              }}></div>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{
                fontSize: '8px',
                fontWeight: 500,
                letterSpacing: '0.6px',
                margin: '6px 6px 5px 6px',
                padding: '2.5px 3px 0.5px 4px',
                borderRadius: '10px',
                border: '0.5px solid #a0a0a0',
                textAlign: 'center',
              }}>
                BUKA
              </Typography>
            </Grid>
          </Grid>
        }
      </div>
    </Draggable>
  );
};


const countdownRenderer = ({ hours, minutes, seconds, completed }: any) => {
  // const classes = useStyles();

  if (completed) {
    return (
    <div/>);
  } else {
    return (
    <Box style={{
      margin: '7px 0px 5px 0px',
      padding: '2px 6px 1px 6px',
      borderRadius: '20px',
      color: '#ffffff',
      backgroundColor: '#121212',
    }}>
      <Typography style={{
        fontSize: '9px',
        fontFamily: 'OakesGrotesk',
        fontWeight: 400,
      }}>{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</Typography>
    </Box>)
  }
};

export function changeFileDataSource(fileUrl: string): string {
  const resultString = fileUrl
  .replaceAll("https://images-far-east.s3.ap-southeast-1.amazonaws.com/", "https://studiosclo.uk/legacy/")
  .replaceAll("https://d31zz7s33c4k72.cloudfront.net/", "https://studiosclo.uk/legacy/")
  .replaceAll("https://files-far-east.s3.ap-southeast-1.amazonaws.com/", "https://studiosclo.uk/");
  return resultString;
}

// Main Stream Popup
const DialogSendMessageLivestream = ({isOpen, setLsDialogSendMessageOpen, lsParticipantId, wsConnection, stageInfo }: 
  {isOpen: boolean, setLsDialogSendMessageOpen: (nextState: boolean) => void, lsParticipantId: string, wsConnection: any, stageInfo: any}) => {
  const classes = useStyles();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const history = useHistory();
  const [lsKirimText, setLsKirimText] = React.useState('');
  const productsDictionary = useAppSelector((state) => state.productsList.productsDictionary);
  const productSizeAvailabilityDict = useAppSelector(state => state.productsList.productSizeAvailabilityDict);
  const productSpotlightList = useAppSelector(state => state.cart.productSpotlightList);
  
  const [lastRenderTime, setLastRenderTime] = React.useState<string>('');
  const [productSpotlight, setProductSpotlight] = React.useState<ProductSpotlight | null>(null);

  // console.log('DAFTAR PRODUK SPOTLIGHT: ' + JSON.stringify(productSpotlightList))

  useEffect(() => {
    const findProductSpotlight = () => {
      const currentTime = new Date().toISOString() 
      const foundSpotlight = productSpotlightList.find(spotlight => (spotlight.isMainSpotlight && (currentTime < spotlight.timestampLimit) && (currentTime > spotlight.timestampStart)));
      console.log('FOUND: ' + JSON.stringify(foundSpotlight));
      return foundSpotlight ? foundSpotlight : null;
    };

    const spotlight = findProductSpotlight();
    setProductSpotlight(spotlight);
  }, [productSpotlightList]);

  
  console.log(productSpotlight)

  let product = null
  let productOriginalPrice = 0
  let productName = ''
  let productRemaining = 0
  let productDisplayImage = ''
  let productDiscount = 0
  let chatMargin = '7px'

  if (productSpotlight) {
    product = productsDictionary[productSpotlight.productId];
    productOriginalPrice = product?.originalPrice ? product.originalPrice : 100000;
    productName = product?.name ? product.name : "Product Name";
    productRemaining = productSizeAvailabilityDict?.[productSpotlight.productId]?.numberOfItemsRemaining;
    productDisplayImage = product?.displayImage ? product.displayImage : "display_image_url";
    productDiscount = Math.floor( (1 - (productSpotlight.discountedPrice / productOriginalPrice)) * 100 );
    chatMargin = '30%'
  }

  return (
    <Dialog
    open={isOpen}
    TransitionComponent={Transition}
    keepMounted
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    onClose={() => {
      setLsDialogSendMessageOpen(false);
    }} className={classes.dialogSendMessageLivestream}>
      <Grid container style={{
        padding: stageInfo?.event ? '20px 11px 21px' : '27px 11px 21px',
      }}>
        {/* {
          stageInfo?.event === "dokterKain" || stageInfo?.event === "konsultasiSize" ?
          <>
            <Grid item xs={5} style={{
              height: '52px',
              position: 'relative',
            }}>
              <Player
                key='regular-animation'
                autoplay
                loop
                src={imageLottieDict[stageInfo.event as string]}
                style={imageLottieDictStyle[(stageInfo.event as string) + "MobileFull"]}
              />
            </Grid>
            <Grid item xs={7} style={{
              height: '59px',
            }}>
              <Typography style={{
                marginRight: '2px',
                marginBottom: '2px',
                fontSize: '77%',
                fontWeight: 600,
                letterSpacing: '0.07vw',
              }}>
                {imageLottieTitle[stageInfo.event as string]}
              </Typography>
              <Typography style={{
                marginRight: '18px',
                fontSize: '1.8vw',
                fontWeight: 300,
                color: '#5f5f5f',
                letterSpacing: '0.03vw',
              }}>
                {imageLottieDescription[stageInfo.event as string]}
              </Typography>
            </Grid>
          </>:
          null
        } */}
        <Grid item xs={12} style={{
          marginBottom: '6px'
        }}>
          <div id='remote-media-full'>
            <div 
              id={videoMainStreamId + '-container-full'}
              style={{
                position: 'relative',
              }}
            >
              <video id={videoMainStreamId + '-full'} style={{width: '100%', borderRadius: '18px'}} autoPlay muted playsInline/>
              {/* Catatan: ini chatnya susah dibaca */}
              <Box
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  padding: theme.spacing(2),
                  background:
                    "linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%)",
                  color: "white",
                  height: "50%",
                  zIndex: 1300,
                  pointerEvents: "none",
                  borderRadius: 20
                }}
              />
              <div 
                id="chat-ws-logs" 
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  display: 'inline-flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  padding: '0px 16px 4px',
                  pointerEvents: 'none',
                  marginBottom: chatMargin,
                  overflowY: 'scroll',
                  height: '200px',
                  borderRadius: '10px',
                  color: 'white',
                  zIndex: 1500,
                  '--mask': `linear-gradient(to top, 
                    rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 40%, 
                    rgba(0,0,0, 0) 95%, rgba(0,0,0, 0) 0
                  ) 100% 50% / 100% 100% repeat-x`,
                  WebkitMask: 'var(--mask)',
                  mask: 'var(--mask)',
                } as React.CSSProperties}>
              </div>
              {
                productSpotlight &&
                <Box
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    padding: '4%',
                    color: "#121212",
                    width: '100%',
                    zIndex: 1700,
                    // pointerEvents: "none",
                    boxSizing: 'border-box',
                    aspectRatio: '3'
                  }}
                >
                  {/* Container */}
                  <Box
                    onClick={() => {
                      if (productSpotlight?.productId) {
                        history.push('/shop/product/' + productSpotlight.productId);
                        setLsDialogSendMessageOpen(false);
                      }
                    }}
                    style={{
                      display: 'flex',
                      flexBasis: '100%',
                      width: '100%',
                      height: '100%',
                      background: 'white',
                      borderRadius: 10
                    }}
                  >
                    {/* Image */}
                    <Box
                      style={{
                        height: '100%',
                        objectFit: 'contain',
                        aspectRatio: 'calc(2/3)'
                      }}
                    >
                      <img src={productDisplayImage} style={{height: '100%', width: '100%', borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}/>
                    </Box>

                    {/* Content */}
                    <Box
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        position: 'relative',
                      }}
                    >
                      {/* Left side */}
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                          height: '100%',
                          padding: '0px 0px 0px 7px',
                        }}
                      >
                        {

                        }
                        {/* Timer */}
                        <Countdown
                          date={productSpotlight?.timestampLimit}
                          renderer={countdownRenderer}
                          onComplete={(timeDelta, completedOnStart) => {
                            setTimeout(() => {
                              setLastRenderTime(new Date().toISOString())
                            }, 400)
                          }}
                        />
        
                        <Typography style={{
                          marginTop: '2px',
                          marginBottom: '4px',
                          fontWeight: 500,
                          fontSize: '13px',
                          fontFamily: 'OakesGrotesk',
                        }}>
                          {productName}
                        </Typography>

                        {/* Sisa */}
                        {/* <Box>
                        {
                          productRemaining && productRemaining < 14 ?
                            <Box className={classes.sisaBox}>
                              <Typography className={classes.sisaText}>
                                Sisa {productRemaining} pcs
                              </Typography>
                            </Box> :
                            null
                        }
                        </Box> */}

                        {/* Harga */}
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            marginBottom: '10px',
                          }}
                        >
                          <Typography style={{
                            fontSize: '9px',
                            fontWeight: 400,
                          }}>
                            Rp {productSpotlight?.discountedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " "}
                          </Typography>
                          <Typography style={{
                            color: 'red',
                            fontSize: '7px',
                            fontWeight: 400,
                            textDecoration: 'line-through',
                            marginLeft: '5px',
                          }}>
                            Rp {productOriginalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " "}
                          </Typography>
                          <Box style={{
                            marginLeft: '6px',
                            padding: '2px 4px 1px 4px',
                            backgroundColor: 'red',
                            color: 'white',
                            borderRadius: '20px',
                          }}>
                            <Typography style={{
                              fontSize: '7px',
                              letterSpacing: '0.3px',
                            }}>
                              -{productDiscount}%
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      {/* Right side */}
                      <Box style={{
                        position: 'absolute',
                        right: '6px',
                        bottom: '6px',
                        padding: '4px 9px 2px 9px',
                        backgroundColor: '#121212',
                        color: '#ffffff',
                        borderRadius: '20px',
                      }}>
                        <Typography style={{
                          fontWeight: 400,
                          fontSize: '9px',
                          fontFamily: "OakesGrotesk",
                          letterSpacing: '0.7px',
                        }}>BELI</Typography>
                      </Box>
                    </Box>
                  </Box>
              </Box>
              }
              {/* Flash Sale */}
              
            </div>
          </div>
          {/* Chat ala TikTok */}
        </Grid>
        {/* <Grid item xs={12}>
          <div id='chat-ws-logs' style={{
            height: '80px',
            overflowY: 'scroll',
            backgroundColor: '#f4f4f4',
            padding: '0px 16px 4px',
            color: '#232323',
            borderRadius: '10px',
            border: '1px solid #E0E0E0',
            marginBottom: '7px'
          }}></div>
        </Grid> */}
        <Grid item xs={8}>
          <TextField
          style={{
            marginLeft: '15px',
          }}
            margin="dense"
            fullWidth
            multiline
            id="livestreamChatInput"
            name="livestreamChatInput"
            label="Kirim Chat"
            type="text"
            value={lsKirimText}
            error={false}
            onChange={(e) => {
              setLsKirimText(e.target?.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}/>
        </Grid>
        <Grid item xs={4} style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          borderRadius: '20px',
        }}>
          <Button onClick={() => {
            if (lsKirimText !== '') {
              // sendWebsiteLivestreamMessage('[' + lsParticipantId + ']' + lsKirimText);
              if (wsConnection) {
                wsConnection.send(JSON.stringify({
                  "Action": "SEND_MESSAGE",
                  "Content": lsKirimText,
                }));
              }
            }
            setLsKirimText('');
          }} style={{
            backgroundColor: '#232323',
            color: '#ffffff',
            fontWeight: 400,
            letterSpacing: '0.9px',
            fontSize: '12px',
            padding: '4px 14px 1px 14px',
            borderRadius: '11px',
            marginLeft: '5px',
            marginRight: '10px',
          }}>
            KIRIM
          </Button>
        </Grid>
      </Grid>
    </Dialog>);

}

export interface TailorChatEvent {
  chatUserId: string
  creationDateTime: string
  contentType: "STRING" | "IMAGE" | "INFO"
  content: string
  author: string
}

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});


function App() {
  let query = useQuery();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const currentAccountState = useAppSelector(state => state.account);
  const productsListStatus = useAppSelector(state => state.productsList.status);
  const cartState = useAppSelector(state => state.cart);
  const overlayFlowVersionView = useAppSelector(state => state.overlayFlowVersionView);
  const history = useHistory();

  // LIVESTREAM STAGE INFO - Used for event and stage stream is on information
  const [latestStageInfo, setLatestStageInfo] = React.useState<any>({});
  // LIVESTREAM CHAT - Always joined when first available
  const [wsConnection, setWsConnection] = React.useState<any>(null); 

  // LIVESTREAM
  const [streamIsOn, setStreamIsOn] = React.useState(false);
  const [lsDialogSendMessageOpen, setLsDialogSendMessageOpen] = React.useState(false);

  const [isConnected, setIsConnected] = React.useState(socket.connected);

  const refreshLivestream = async () => {
    console.log("[LIVESTREAM] FETCHING STREAM DATA AND JOINING STAGE.");

    const response = await axios({
      method: 'post',
      url: "https://ojm2bfbydd.execute-api.ap-southeast-1.amazonaws.com/UserAPIProd/video-token",
      data: JSON.stringify({
        getStageInfo: true,
        getStageToken: false,
        joinChatRoom: true,
      })
    });
    
    const chatToken = response.data?.joinChatRoom?.token;
    const displayName = response.data?.joinChatRoom?.displayName;

    // CHAT - IS JOINED IF CHAT ROOM INFO EXISTS AND WE HAVEN'T JOINED
    if (response.data?.joinChatRoom && !((window as any).chatWsConnection)) {
      console.log("[LIVESTREAM] CHAT: JOINING");

      const socket = "wss://edge.ivschat.ap-south-1.amazonaws.com";
      const connection = new WebSocket(socket, chatToken);
  
      connection.onopen = () => {
        const payload = {
          "Action": "SEND_MESSAGE",
          "Content": displayName + " baru join.",
        }
        connection.send(JSON.stringify(payload));
      }
  
      connection.onmessage = (event) => {
        const data = JSON.parse(event.data);
  
        if (data?.Content?.startsWith("https")) {
          const chatEl = document.createElement("p");
          chatEl.textContent = data.Sender?.Attributes.displayName + ': [Klik Untuk Buka] ' + data.Content;
          chatEl.style.cssText = 'font-size: 10px; font-family: OakesGrotesk; font-weight: 300';
          chatEl.onclick = () => {
            setLsDialogSendMessageOpen(false);
            history.push(data.Content.substring(22)); // Assume that it always starts with https://www.studios.id/
          }
  
          const chatContainer = document.getElementById('chat-ws-logs');
          if (chatContainer) {
            chatContainer.appendChild(chatEl);
            chatContainer.scrollTop = chatContainer.scrollHeight;
          }
  
        } else {
          const chatEl = document.createElement("p");
          chatEl.textContent = data.Sender?.Attributes.displayName + ': ' + data.Content;
          chatEl.style.cssText = 'font-size: 9.5px; font-family: OakesGrotesk; font-weight: 300; margin: 3px 0px 7px 0px; letter-spacing: 0.6px';
  
          const chatContainer = document.getElementById('chat-ws-logs');
          if (chatContainer) {
            chatContainer.appendChild(chatEl);
            chatContainer.scrollTop = chatContainer.scrollHeight;
          }
        }
      };

      console.log("[LIVESTREAM] CHAT: JOINED TO CHAT ROOM");
      setWsConnection(connection); // For in react
      (window as any).chatWsConnection = connection // For event callbacks (state in react is not updated after function creation)
    } else {
      console.log("[LIVESTREAM] CHAT: ALREADY JOINED. RETURNING");
    }

    // STAGE INFO - IS ALWAYS UPDATED.
    if (response.data?.getStageInfo) {
      console.log("[LIVESTREAM] STAGE INFO: UPDATING STAGE INFO.");
      setLatestStageInfo(response.data.getStageInfo); // For in react
      (window as any).livestreamStageInfo = response.data.getStageInfo; // For event callbacks (state in react is not updated after function creation)

      const streamIsOnNew = response.data.getStageInfo.streamIsOn;
      console.log('SETTING STREAM ON ' + streamIsOnNew)
      setStreamIsOn(streamIsOnNew);

      // Update product spotlight list
      const productSpotlightList = response.data.getStageInfo.productSpotlightList;
      // dispatch(setProductSpotlightList(productSpotlightList));

      // const mainEl: HTMLVideoElement = document.getElementById(videoMainStreamId) as HTMLVideoElement;
      // const fullEl: HTMLVideoElement = document.getElementById(videoMainStreamId + '-full') as HTMLVideoElement;
      
      // if (mainEl) {
      //   if (streamIsOn) {
      //     mainEl.play();
      //   } else {
      //     mainEl.pause();
      //   }
      // }
      // if (fullEl) {
      //   if (streamIsOn) {
      //     fullEl.play();
      //   } else {
      //     fullEl.pause();
      //   }
      // }
    }
  };


  useEffect(() => {
    // ADD HISTORY BY DEFAULT IF IN PRODUCT PAGE ALREADY
    if (history.location.pathname.includes('/shop/product')) {
      history.push(history.location.pathname);
    }

    // LIVESTREAM
    refreshLivestream();

    // TAILOR CHAT
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onChatMessageReceive(value: TailorChatEvent) {
      if (value.content === "L2310") {
        // Display video
        refreshLivestream();
        // setTimeout(() => {
        //   setStreamIsOn(true);
        // }, 500);
        return;
      }

      if (value.contentType === "INFO" && 
        value.content === "TYPING" &&
        value.author === "OTHER") {
        const chatTypingEl = document.getElementById('chat-tailor-typing-indicator');
        if (chatTypingEl) {
          chatTypingEl.style.visibility = 'visible';
          const startVisibleString = new Date().toISOString();
          (window as any).chatTypingElVisibleDate = startVisibleString;
          setTimeout(() => {
            if ((window as any).chatTypingElVisibleDate && 
            startVisibleString === (window as any).chatTypingElVisibleDate) {
              chatTypingEl.style.visibility = 'hidden';
            }
          }, 2000);
        }
        return;
      }

      dispatch(newChatMessage(value));

      const chatContainer = document.getElementById('chat-tailor-logs');
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('chat message', onChatMessageReceive);

    // Get chat history
    dispatch(getChatHistory({ chatUserId: "ALL" }));

    // SESSION START
    Mixpanel.track('Session start');
    const distinct_id = Mixpanel.get_distinct_id();
    (window as any).hj('identify', distinct_id, {
      'Id Mixpanel': distinct_id
    });

    // Collect up to 40 random Hotjar sessions a day, from current of 600 sessions
    if (Math.random() <= (40 / 600)) 
    {
      // START HOTJAR RECORDING
      (window as any).hj('event', 'start_hotjar_rec');
      console.log("START-HOTJAR-REC");
    }

    setUserIdentifier(distinct_id.substring(0, 25));

    // We send a notification to SMS that the website is accessed once

    var dariFacebook = window.location.href.indexOf('?fbclid=') !== -1;
    var asalBuka = dariFacebook ? " IG Link " : " URL ";

    if (window.location.href.indexOf("careers") === -1) { // If careers is not found
      sendWebsiteEventMessage("New visitor web dari " + asalBuka + ", mulai dari x" + window.location.href);
    }

    const localStorageFlowVersion = localStorage.getItem("flowVersion");
    if (localStorageFlowVersion !== null) {
      let savedState: FlowVersionState = JSON.parse(localStorageFlowVersion);
      if (savedState) {
        dispatch(loadFlowVersionState(savedState));
      }
    }

    const collectionQueryKeyValue = query.get("collectionkey");
    if (collectionQueryKeyValue) {
      dispatch(setCollectionQueryKey(collectionQueryKeyValue));
    }
    const sortQueryKeyValue = query.get("sortkey");
    if (sortQueryKeyValue) {
      dispatch(setSortQueryKey(sortQueryKeyValue));
    }

    const queryFlowVersionValue = query.get("flowversion");
    if (queryFlowVersionValue !== null && queryFlowVersionValue !== ""
      && (queryFlowVersionValue === "softintro1" || queryFlowVersionValue === "hardintro1")) {
      dispatch(setFlowVersion(queryFlowVersionValue));
      sendWebsiteEventMessage("User masuk ke flow version " + queryFlowVersionValue);
      const pathName = (window as any).location.pathname;

      if (pathName) {
        history.push(pathName); // Added such that when navbar back is pressed, it doesn't go to current path
      }
    }

    // Set FB Click ID links to be softintro1 by default
    const queryFlowVersionValueFbc = query.get("fbclid");
    if (queryFlowVersionValueFbc !== null && queryFlowVersionValueFbc !== "" && queryFlowVersionValueFbc.length > 0) {

      const pathName = (window as any).location.pathname;

      if (pathName && pathName.startsWith("/shop/category/")) {
        dispatch(setFlowVersion("softintro1"));
        sendWebsiteEventMessage("User masuk ke flow version " + "softintro1");
        history.push(pathName); // Added such that when navbar back is pressed, it doesn't go to current path
      }
    }

    (window as any).studio_s_customer_name = "";

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('chat message', onChatMessageReceive);
    };

  }, []);

  // Set distinct ID if there is none
  if (currentAccountState.distinctUserId === '') {
    if (localStorage.getItem("distinctUserId") === null) {
      console.log("Setting new user ID " + new Date().toISOString());
      const newDistinctUserId = 'DUID-' + new Date().toISOString();
      dispatch(updateState({
        distinctUserId: newDistinctUserId
      }));
      localStorage.setItem("distinctUserId", newDistinctUserId);
    }
  }

  // We fetch when starting or when signed in but token already expired
  const fetchAuthStateFromLocalStorage = currentAccountState.authState === 'starting'
    || (currentAccountState.authState === 'signedin' && (currentAccountState.tokenExpiration && Date.now() >= parseInt(currentAccountState.tokenExpiration) * 1000));
  if (fetchAuthStateFromLocalStorage) {

    console.log("Attempting fetch auth state from local storage");
    console.log(JSON.stringify(currentAccountState, null, 2));

    const localStorageAccount = localStorage.getItem("account");
    if (localStorageAccount !== null) {
      let savedState: AccountState = JSON.parse(localStorageAccount);
      if (savedState &&
        savedState.authState === 'signedin' &&
        savedState.tokenExpiration && 
        Date.now() < parseInt(savedState.tokenExpiration) * 1000) {

        dispatch(updateState({
          ...savedState,
          chatUserId: savedState.chatUserId ? savedState.chatUserId : 'USER-' + Math.floor(Math.random() * 100000),
          statusSignIn: APIRequestStatus.Idle,
          signInError: '',
          statusSignUp: APIRequestStatus.Idle,
          signUpError: '',
        }));

        // Facebook External ID Tracking
        if ((window as any).fbq !== null) {
          const newUserData = {
            "external_id": savedState.sub,
            "ph": savedState.phoneNumber.replace(/[^\d]/g, ''),
            "em": savedState.email
          };
          console.log("Used signed in, linking pixel to external id " + JSON.stringify(newUserData));
          // (window as any).fbq('init', '409745674022991', newUserData);
          (window as any).fbq('init', '482082398095905', newUserData);
        }

        (window as any).studio_s_customer_name = savedState.name;

        // Update last visit time to server
        axios({
          method: 'post',
          url: "https://ojm2bfbydd.execute-api.ap-southeast-1.amazonaws.com/UserAPIProd/activity",
          data: JSON.stringify({
            phoneNumber: savedState.phoneNumber,
            eventType: "NEWVISIT",
            creationDateTime: new Date().toISOString(), 
          })
        });
        sendWebsiteEventMessage("User " + savedState.phoneNumber + " just started a visit");

        dispatch(uploadLocalUnsavedMeasAndSynchronize());
        dispatch(fetchOrdersList());
        dispatch(fetchAddresses());
      } else {
        console.log("Local storage found, but invalid.");
        dispatch(updateState({
          chatUserId: 'USER-' + Math.floor(Math.random() * 100000),
          authState: 'signedout',
          statusSignIn: APIRequestStatus.Idle,
          signInError: '',
          statusSignUp: APIRequestStatus.Idle,
          signUpError: '',
        }));
      }
    } else {
      console.log("No local storage found.");
      dispatch(updateState({
        chatUserId: 'USER-' + Math.floor(Math.random() * 100000),
        authState: 'signedout',
        statusSignIn: APIRequestStatus.Idle,
        signInError: '',
        statusSignUp: APIRequestStatus.Idle,
        signUpError: '',
      }));
    }
  }

  // We check availability of items in cart if there is items, but the hash is empty / not checked
  if (cartState.cartProductList.length !== 0 && cartState.cartProductListHash === '') {
    console.log("Checking current cookie cart items availability");
    dispatch(postCheckCurrentCartAvailability({}));
  }

  if (productsListStatus === APIRequestStatus.Idle) {
    console.log("start fetch");
    dispatch(fetchProductsList({}));
    dispatch(fetchCategoryReviewRecap(''));
  }

  return (
      <ThemeProvider theme={theme}>
        <div className={classes.contentRoot}>
          <FloatingLiveStream streamIsOn={streamIsOn} lsDialogSendMessageOpen={lsDialogSendMessageOpen} openSendMessage={() => setLsDialogSendMessageOpen(true)} wsConnection={wsConnection} stageInfo={latestStageInfo}/>
          <DialogSendMessageLivestream isOpen={streamIsOn && lsDialogSendMessageOpen} setLsDialogSendMessageOpen={(nextState: boolean) => setLsDialogSendMessageOpen(nextState)} lsParticipantId={'string'} wsConnection={wsConnection} stageInfo={latestStageInfo}/>
          <ScrollToTopComponent />
          <Switch>
            <Route path="/staging-area">
            
            </Route>            
            <Route path="/mailing">
              <NavBar transparentAtTop={false} transparent={false} coloredAtTop={false} largerTitleAtTop={false} />
              <PageMailingPreference />
            </Route>
            <Route path="/shop">
              <NavBar transparentAtTop={false} transparent={true} coloredAtTop={false} largerTitleAtTop={false} />
              <BannerOngkir hideView={true}/>
              <Toolbar />
              <PageProduct />
            </Route>
            <Route path="/your-account">
              <NavBar transparentAtTop={true} transparent={true} coloredAtTop={false} largerTitleAtTop={false} />
              <Toolbar />
              <Toolbar />
              <PageAccount />
            </Route>
            <Route path="/checkout">
              <PageCheckout />
            </Route>
            <Route path="/careers">
              <PageCareers />
            </Route>
            <Route path="/create-personal-size-with-kit/:userSub">
              <PageCreatePersonalSizeMeasKit />
            </Route>
            <Route path="/make-size-from-other/:userItemQRSizeId">
              <PageCreatePersonalSizeFromItemQR />
            </Route>
            <Route path="/repurchase-coupon-first/:userSub">
              <PageCreateRepurchaseFlow />
            </Route>
            <Route path="/referral-coupon/:userSub">
              <PageCreateReferralFlow />
            </Route>
            <Route path="/order/:orderId">
              <PageOrder />
            </Route>
            <Route path="/tailor-chat-380028bafbb3881jsa088181jjaaa">
              <PageTailorChat clientVersion={false} />
            </Route>
            <Route path="/">
              <PageHome />
            </Route>
          </Switch>
          <Switch>
            <Route path="/careers">
            </Route>
            <Route path="/create-personal-size-with-kit">
            </Route>
            <Route path="/tailor-chat-380028bafbb3881jsa088181jjaaa">
            </Route>
            <Route>
              <PageHomeFooter />
            </Route>
          </Switch>
          <Switch>
            <Route path="/shop">
              <Bantuan useTailor={false} hideBantuan={false} showPersonalSizeProfile={false} openPersonalSizeOverlay={() => dispatch(openOverlayPersonalSize())} />
            </Route>
            <Route>
            </Route>
          </Switch>
          <Switch>
            <Route path="/shop">
              <PromoFloat />
            </Route>
            <Route>
            </Route>
          </Switch>
          <OverlayViewAccount />
          <OverlayViewPopupMeasurementKit />
          <OverlayViewMeasurementMethodSelection />
          <OverlayViewMeasurementInstantSizeTeam />
          <OverlayViewMeasurementCreateNew />
          <OverlayViewMeasurementCreateNewRatings />
          <OverlayViewMeasurementSelection />
          <OverlayViewMeasurementClothingView />
          <OverlayViewMeasurementClothingEdit />
          <OverlayViewUniversalSizeFlow />
          <OverlayViewUniversalSizeCreateMeasFlow />
          <OverlayViewOfferSignUp />
          <OverlayViewAddress />
          <OverlayViewPersonalSize />
          <OverlayViewPersonalSizePromo />
          <OverlayViewPopupFlowVersion />
          <OverlayViewPopupOngkir />
          <OverlayViewPopupInstantSizeTeam />
          <OverlayViewPopupQRResult />
          <OverlayViewPopupMarketplaceStart />
          <OverlayViewPopupRepurchase />
          { streamIsOn && <LiveStream url='https://liveserver.studios.id:443/rtc/v1/whep/?app=live&stream=livestream'/> }
        </div>
      </ThemeProvider>
  );
}

const dummyProductSpotlightList = [
  {
    productId: '2023-10-KnitTeeCollection-WaffleTeeBrown',
    discountedPrice: 110000,
    timestampLimit: '2024-07-01T12:00:00.000Z',
    isMainSpotlight: false
  },
  {
    productId: '2024-03-KokoCollection-RayaSloperinMidtoneGrey',
    discountedPrice: 100000,
    timestampLimit: '2024-07-01T12:00:00.000Z',
    isMainSpotlight: false
  },
]

export default App;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import { RootState } from '../store';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import jwt_decode from 'jwt-decode';
import { postUserPersonalSizeInput, uploadLocalUnsavedMeasAndSynchronize } from './MeasurementsRedux';
import { fetchOrdersList } from './OrdersListRedux';
import { fetchAddresses } from './AddressRedux';
import { useDispatch } from 'react-redux';
import { sendWebsiteEventMessage } from '../telegrambotevents';
import { UserPersonalSizeInput } from 'common-ts/dist/models/UserMeasurements';
import { removeCurrentCoupon, submitCouponRequest } from './CartRedux';
import { Mixpanel } from '../mixpanel';
import { TailorChatEvent } from '../App';

export interface AccountState {
  authState: 'signedin' | 'signedout' | 'starting',

  email: string,
  emailVerified: 'true' | 'false' | 'unknown',
  phoneNumber: string,
  phoneNumberVerified: 'true' | 'false' | 'unknown',
  name: string,
  sub: string,
  paidOrderCount: number,
  firstOrderTime: string,

  chatUserId: string,
  chatEvents: TailorChatEvent[],

  idToken: string | undefined,
  tokenExpiration: string | undefined,

  statusSignIn: APIRequestStatus,
  signInError: string,
  statusSignUp: APIRequestStatus,
  signUpError: string,

  distinctUserId: string,
}

let initialState: AccountState = {
  authState: 'starting',
  email: '',
  emailVerified: 'unknown',
  phoneNumber: '',
  phoneNumberVerified: 'unknown',
  name: '',
  sub: '',
  paidOrderCount: 0,
  firstOrderTime: '',
  chatUserId: '',
  chatEvents: [],
  idToken: undefined,
  tokenExpiration: undefined,
  statusSignIn: APIRequestStatus.Idle,
  signInError: '',
  statusSignUp: APIRequestStatus.Idle,
  signUpError: '',

  distinctUserId: localStorage.getItem('distinctUserId') ? localStorage.getItem('distinctUserId') as string : '',
}

export const getChatHistory = createAsyncThunk(
  'account/getChatHistory', 
  async (payload: {
    chatUserId: string
  }, thunkAPI) => {

  var config: AxiosRequestConfig = {
    method: 'get',
    url: "https://ojm2bfbydd.execute-api.ap-southeast-1.amazonaws.com/UserAPIProd/chat",
    params: {
      chatUserId: "ALL"
    }
  };

  let response = await axios(config);

  return response.data;
})

export const signUp = createAsyncThunk(
  'account/signUp', 
  async (payload: {
    password: string
  }, thunkAPI) => {
  const url = 'https://ojm2bfbydd.execute-api.ap-southeast-1.amazonaws.com/UserAPIProd/account/signup';

  const rootState = (thunkAPI.getState() as RootState);

  var config: AxiosRequestConfig = {
    method: 'post',
    url: url,
    data: JSON.stringify({
      email: rootState.account.email,
      phoneNumber: rootState.account.phoneNumber,
      name: rootState.account.name,
      password: payload.password,
    })
  };

  let response = await axios(config);

  if (response.data.userSignUpStatus === "SUCCESS") {
    sendWebsiteEventMessage("Sign up for " + rootState.account.name + " successful");
    thunkAPI.dispatch(signIn({ password: payload.password, useEmail: true , otpValue: ''}));
  } else {
    sendWebsiteEventMessage("Sign up for " + rootState.account.name + " failed");
    thunkAPI.dispatch(updateState({
      signUpError: response.data.message
    }))
  }

  return response.data;
})

export const signIn = createAsyncThunk(
  'account/signIn', 
  async (payload: {
    password: string
    useEmail: boolean
    otpValue: string
    subTempPass?: string
    userSub?: string
  }, thunkAPI) => {
  const url = 'https://ojm2bfbydd.execute-api.ap-southeast-1.amazonaws.com/UserAPIProd/account/login';

  const rootState = (thunkAPI.getState() as RootState);

  var config: AxiosRequestConfig = {
    method: 'post',
    url: url,
    data: JSON.stringify({
      email: payload.useEmail ? rootState.account.email : undefined,
      phoneNumber: !payload.useEmail ? rootState.account.phoneNumber : undefined,
      password: payload.password,
      otpValue: payload.otpValue,
      otpStatus: payload?.otpValue?.length ? "otp_login" : undefined,
      subTempPass: payload?.subTempPass ? payload.subTempPass : undefined,
      userSub: payload?.userSub ? payload.userSub : undefined,
    })
  };

  let response = await axios(config);

  if (response.data.userLogInStatus === "SUCCESS") {
    let decodedToken: any = jwt_decode(response.data.data.token);

    const previousChatUserId = rootState.account.chatUserId;
    const newChatUserId = decodedToken.chatUserId ? decodedToken.chatUserId : rootState.account.chatUserId ? rootState.account.chatUserId : '';
  
    thunkAPI.dispatch(updateState({
      email: decodedToken.email,
      emailVerified: decodedToken.emailVerified,
      phoneNumber: decodedToken.phoneNumber,
      phoneNumberVerified: decodedToken.phoneNumberVerified,
      name: decodedToken.name,
      sub: decodedToken.sub,
      paidOrderCount: decodedToken.paidOrderCount ? decodedToken.paidOrderCount : 0,
      firstOrderTime: decodedToken.firstOrderTime ? decodedToken.firstOrderTime : '',
      chatUserId: decodedToken.chatUserId ? decodedToken.chatUserId : rootState.account.chatUserId ? rootState.account.chatUserId : '',
      tokenExpiration: decodedToken.exp + '',
      idToken: response.data.data.token + '',
      authState: 'signedin',
    }));

    if (previousChatUserId !== newChatUserId) {
      thunkAPI.dispatch(getChatHistory({ chatUserId: newChatUserId }));
    }

    (window as any).studio_s_customer_name = decodedToken.name;

    // Facebook External ID Tracking
    if ((window as any).fbq !== null) {
      const newUserData =  {
        "external_id": decodedToken.sub,
        "ph": decodedToken.phoneNumber.replace(/[^\d]/g, ''),
        "em": decodedToken.email
      };
      console.log("Sign in successful, linking pixel to external id " + JSON.stringify(newUserData));
      // (window as any).fbq('init', '409745674022991', newUserData);
      (window as any).fbq('init', '482082398095905', newUserData);
    }

    // Update last visit time to server
    axios({
      method: 'post',
      url: "https://ojm2bfbydd.execute-api.ap-southeast-1.amazonaws.com/UserAPIProd/activity",
      data: JSON.stringify({
        phoneNumber: decodedToken.phoneNumber,
        eventType: "NEWVISIT",
        creationDateTime: new Date().toISOString(), 
      })
    });
    sendWebsiteEventMessage("User " + decodedToken.phoneNumber + " just started a visit");

    thunkAPI.dispatch(uploadLocalUnsavedMeasAndSynchronize());
    thunkAPI.dispatch(fetchOrdersList());
    thunkAPI.dispatch(fetchAddresses());

    // Update and create a personal input entry. This is very wasteful, but acceptable for now.
    const userPersonalSizeInputNewEntry: UserPersonalSizeInput = {
      userSub: decodedToken.sub,
      name: decodedToken.name,
    }
    thunkAPI.dispatch(postUserPersonalSizeInput(userPersonalSizeInputNewEntry));

    const currentCouponKey = rootState.cart.couponKey;
    thunkAPI.dispatch(removeCurrentCoupon());
    if (currentCouponKey && currentCouponKey !== "") {
      thunkAPI.dispatch(submitCouponRequest(currentCouponKey));
    }

    Mixpanel.identify(decodedToken.sub);
    Mixpanel.track('signInAttemptSuccess', {
      "userSub": decodedToken.sub
    });

  } else {
    thunkAPI.dispatch(updateState({
      signInError: response.data.message
    }))
  }

  return response.data;
})

export const signOutAndReset = createAsyncThunk(
  'account/signOutAndReset', 
  async () => {
  
  // Facebook External ID Tracking
  if ((window as any).fbq !== null) {
    const newUserData =  {
      "external_id": "",
      "ph": "",
      "em": ""
    };
    console.log("Sign out, linking pixel to external id " + JSON.stringify(newUserData));
    // (window as any).fbq('init', '409745674022991', newUserData);
    (window as any).fbq('init', '482082398095905', newUserData);
  }
    
  Mixpanel.reset();

  return null;
})

function combineChatEventsList(previousEventList: TailorChatEvent[], newEvent: TailorChatEvent): TailorChatEvent[] {
  const alreadyExistDict: {[key: string]: boolean} = {};

  for (let prevEvent of previousEventList) {
    alreadyExistDict[prevEvent.creationDateTime] = true;
  }

  const newCreationDateTime = newEvent.creationDateTime;

  if (alreadyExistDict[newCreationDateTime]) {
    return [...previousEventList];
  } else {
    return [...previousEventList, newEvent];
  }
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    updateState: (state, action) => {
      const payload = action.payload;
      Object.assign(state, payload);
  
      localStorage.setItem("account", JSON.stringify(state));
    },
    newChatMessage: (state, action) => {
      const payload = action.payload;
      Object.assign(state, {
        chatEvents: combineChatEventsList(state.chatEvents, payload),
      });
    },
  },
  extraReducers(builder) {
    builder
      .addCase(signOutAndReset.pending, (state, action) => {
      })
      .addCase(signOutAndReset.fulfilled, (state, action) => {
        Object.assign(state, {
          authState: 'signedout',
          email: '',
          emailVerified: 'unknown',
          phoneNumber: '',
          phoneNumberVerified: 'unknown',
          name: '',
          sub: '',
          paidOrderCount: 0,
          firstOrderTime: '',
          chatUserId: 'USER-' + Math.floor(Math.random() * 100000),
          chatEvents: [],
          idToken: undefined,
          tokenExpiration: undefined,
          statusSignIn: APIRequestStatus.Idle,
          signInError: '',
          statusSignUp: APIRequestStatus.Idle,
          signUpError: '',
        });
        localStorage.setItem("account", JSON.stringify(state));
      })
      .addCase(signOutAndReset.rejected, (state, action) => {
      })
      .addCase(signIn.pending, (state, action) => {
        state.statusSignIn = APIRequestStatus.RequestInProgress;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.statusSignUp = APIRequestStatus.Success; // In case sign up is executed before sign in
        state.statusSignIn = APIRequestStatus.Success;
      })
      .addCase(signIn.rejected, (state, action) => {
        console.log("fetch is failure");
        state.statusSignIn = APIRequestStatus.Failure;
      })
      .addCase(signUp.pending, (state, action) => {
        state.statusSignUp = APIRequestStatus.RequestInProgress;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        // We still consider this to be in progress, since we sign in right after sign up
      })
      .addCase(signUp.rejected, (state, action) => {
        console.log("fetch is failure");
        state.statusSignUp = APIRequestStatus.Failure;
      })
      .addCase(getChatHistory.pending, (state, action) => {
      })
      .addCase(getChatHistory.fulfilled, (state, action) => {
        if (action.payload?.content) {
          state.chatEvents = action.payload.content.slice().reverse();
        }
      })
      .addCase(getChatHistory.rejected, (state, action) => {
      })
  }
})

// Action creators are generated for each case reducer function
export const { updateState, newChatMessage } = accountSlice.actions;

export default accountSlice.reducer;
import { configureStore } from '@reduxjs/toolkit';

import accountReducer from './redux/AccountRedux';
import addressListReducer from './redux/AddressRedux';
import cartReducer from './redux/CartRedux';
import userMeasurementsReducer from './redux/MeasurementsRedux';
import ordersListReducer from './redux/OrdersListRedux';
import productReviewsReducer from './redux/ProductReviewsRedux';
import productReducer from './redux/ProductsListRedux';
import overlayAccountViewReducer from './redux/UIOverlayAccountViewRedux';
import overlayAddressViewReducer from './redux/UIOverlayAddressViewRedux';
import overlayFlowVersionViewReducer from './redux/UIOverlayFlowVersionRedux';
import overlayMeasureInstructionsReducer from './redux/UIOverlayMeasureInstructionsRedux';
import overlayMeasurementCreateViewReducer from './redux/UIOverlayMeasurementCreateViewRedux';
import overlayMeasurementEditViewReducer from './redux/UIOverlayMeasurementEditViewRedux';
import overlayMeasurementInstantSizeTeamViewReducer from './redux/UIOverlayMeasurementInstantSizeTeamViewRedux';
import overlayMeasurementSelectionViewReducer from './redux/UIOverlayMeasurementSelectionViewRedux';
import overlayMeasurementMethodSelectionViewReducer from './redux/UIOverlayMeasurementMethodSelectionViewRedux'
import overlayNavbarReducer from './redux/UIOverlayNavbarRedux';
import overlayOngkirReducer from './redux/UIOverlayOngkirRedux';
import overlayPersonalSizeReducer from './redux/UIOverlayPersonalSizeRedux';
import overlayPopupReducer from './redux/UIOverlayPopupRedux';
import overlaySizeChartReducer from './redux/UIOverlaySizeChartRedux';
import overlayUniversalSizeReducer from './redux/UIOverlayUniversalSizeRedux';
import overlayInstantSizeTeamReducer from './redux/UIOverlayInstantSizeTeamRedux';
import categoryReviewReducer from './redux/CategoryReviewRedux';
import mailingPreferenceReducer from './redux/MailingPreferenceRedux';
import productAnalyticsLogReducer from './redux/ProductAnalyticsRedux';
import overlayMeasurementKitReducer from './redux/UIOverlayMeasurementKitRedux'
import overlayQRResultReducer from './redux/UIOverlayQRResultRedux';
import overlayQRRepurchaseReducer from './redux/UIOverlayQRRepurchaseRedux';
import overlayUniversalSizeCreateMeasReducer from './redux/UIOverlayUniversalSizeCreateMeasRedux';
import overlayMktplcStartReducer from './redux/UIOverlayMktplcStartRedux';

const store = configureStore({
  reducer: {
    account: accountReducer,
    cart: cartReducer,
    productsList: productReducer,
    productReviews: productReviewsReducer,
    userMeasurementsList: userMeasurementsReducer,
    ordersList: ordersListReducer,
    addressList: addressListReducer,
    categoryReviewList: categoryReviewReducer,
    mailingPreference: mailingPreferenceReducer,
    productAnalyticsLog: productAnalyticsLogReducer,
    // UI States
    overlayQRResultView: overlayQRResultReducer,
    overlayMeasurementKit: overlayMeasurementKitReducer,
    overlayAccountView: overlayAccountViewReducer,
    overlayAddressView: overlayAddressViewReducer,
    overlayMeasurementMethodSelectionView: overlayMeasurementMethodSelectionViewReducer,
    overlayMeasurementInstantSizeTeamView: overlayMeasurementInstantSizeTeamViewReducer,
    overlayMeasurementSelectionView: overlayMeasurementSelectionViewReducer,
    overlayMeasurementEditView: overlayMeasurementEditViewReducer,
    overlayMeasurementCreateView: overlayMeasurementCreateViewReducer,
    overlayFlowVersionView: overlayFlowVersionViewReducer,
    overlayPopup: overlayPopupReducer,
    overlayNavbar: overlayNavbarReducer,
    overlaySizeChart: overlaySizeChartReducer,
    overlayOngkir: overlayOngkirReducer,
    overlayMeasureInstructions: overlayMeasureInstructionsReducer,
    overlayPersonalSize: overlayPersonalSizeReducer,
    overlayUniversalSize: overlayUniversalSizeReducer,
    overlayInstantSizeTeam: overlayInstantSizeTeamReducer,
    overlayUniversalSizeCreateMeas: overlayUniversalSizeCreateMeasReducer,
    overlayMktplcStart: overlayMktplcStartReducer,
    overlayQRRepurchase: overlayQRRepurchaseReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
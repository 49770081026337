import { Dialog, Fade } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close } from '@material-ui/icons';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  useParams
} from "react-router-dom";
import { Mixpanel } from '../mixpanel';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import OverlayComponentBaseContainerHorizontal from './OverlayComponentBaseContainerHorizontal';
import { VideoComponent } from './PageComponentElementCarousel';
import { determineMediaType } from './PageProductDetail';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  dialogContainer: {
    height: '100%',
    width: '100%',
    backgroundColor: '#000',
  },
  closeButton: {
    color: '#fff',
  },
  imageContainer: {
    width: '100%',
    height: 'calc(100% - 48px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  carousel: {
    maxHeight: '94%',
    // objectFit: 'cover',
    // aspectRatio: '2/3',
  },
  indicatorItemSelected: {
    display: 'inline-block',
    background: '#fff',
    width: 8,
    height: 8,
    margin: '0px 4px',
    borderRadius: '50%',
  },
  indicatorItem: {
    display: 'inline-block',
    background: '#999',
    width: 8,
    height: 8,
    margin: '0px 4px',
    borderRadius: '50%',
  },
  cartCarouselImage: {
    width: '100%',
    // objectFit: 'cover',
    // aspectRatio: '2/3',
    marginBottom: '36px',
  },
}),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Fade ref={ref} {...props} timeout={170} />;
});

interface Props {
  imageOverlayIndex: number
  setImageOverlayIndex: React.Dispatch<React.SetStateAction<number>>
  imageOverlayOpen: boolean
  setImageOverlayOpen: React.Dispatch<React.SetStateAction<boolean>>
  updateNextAutoslideTime: () => void
  productImages: string[]
}

export default function PageProduct({imageOverlayIndex, setImageOverlayIndex, imageOverlayOpen, setImageOverlayOpen, updateNextAutoslideTime, productImages}: Props) {
  let { productId } = useParams<{ productId: string }>();
  productId = decodeURIComponent(productId); // We decode product URI
  const classes = useStyles();
  const dispatch = useAppDispatch();

  return (
    <Dialog
    fullScreen
    maxWidth="lg"
    open={imageOverlayOpen}
    TransitionComponent={Transition}
    onClose={() => {
      setImageOverlayOpen(false);
      Mixpanel.track('fullscreenImageClose', {
        'productId': "productId-" + productId
      });
    }}
    keepMounted
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    >
      <Box className={classes.dialogContainer}>
        <OverlayComponentBaseContainerHorizontal alignItems="flex-start">
          <IconButton onClick={() => {
              setImageOverlayOpen(false);
              Mixpanel.track('fullscreenImageClose', {
                'productId': "productId-" + productId
              });
            }}
            className={classes.closeButton}>
            <Close/>
          </IconButton>
        </OverlayComponentBaseContainerHorizontal>
        <Box className={classes.imageContainer}>
          <Carousel
            className={classes.carousel}
            selectedItem={imageOverlayIndex}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={12}
            showThumbs={false} 
            showStatus={false}
            showArrows={true}
            showIndicators={true}
            onSwipeStart={() => {
              updateNextAutoslideTime();
            }}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              if (isSelected) {
                  return (
                      <li
                        className={classes.indicatorItemSelected}
                        aria-label={`Selected: ${label} ${index + 1}`}
                        title={`Selected: ${label} ${index + 1}`}
                      >

                      </li>
                  );
              }
              return (
                  <li
                    className={classes.indicatorItem}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                    title={`${label} ${index + 1}`}
                    aria-label={`${label} ${index + 1}`}
                  >
                  </li>
              );
            }}
            onChange={ (index, item) => {
              if (imageOverlayIndex !== index) {
                setImageOverlayIndex(index);
                Mixpanel.track('fullscreenImageSlide', {
                  'index': index,
                  'productId': "productId-" + productId
                });
              }
            }}
            >
            {
              productImages.map((item, i) => {
                  let contentType = determineMediaType(item);
    
                  if (contentType === "image") {
                    return (
                      <div key={"full-img-product-" + productId + i }>
                      <img src={item} className={classes.cartCarouselImage}/>
                    </div>
                    );
                  } else if (contentType === "video") {
                    return (
                      <div key={"full-img-product-" + productId + i }>
                        <VideoComponent 
                          source={item} 
                          videoSoundOn={item?.includes("_with_sound") && imageOverlayOpen === true && imageOverlayIndex === i}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div 
                      key={"full-img-product-" + productId + i }>
                        <img src={item} className={classes.cartCarouselImage} />
                      </div>
                    );
                  }
              })
            }
          </Carousel>
        </Box>
      </Box>
    </Dialog>
  );
}
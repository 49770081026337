import { Box, CircularProgress } from '@material-ui/core';
import {
  useParams, Redirect,
} from "react-router-dom";
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { signIn } from '../redux/AccountRedux';
import { openOverlay as openOverlayRepurchase } from '../redux/UIOverlayQRRepurchaseRedux';
import { sendWebsiteLivestreamMessage } from '../telegrambotevents';
import { setLocalCoupon, submitCouponRequest } from '../redux/CartRedux';

export default function PageCreatePersonalSize() {
  let { userSub } = useParams<{ userSub: string }>();
  const dispatch = useAppDispatch();
  const appliedCoupon = useAppSelector(state => state.cart.coupon);

  // 01. Auto fetch coupon on non-trivial userSub
  useEffect(() => {

    // START HOTJAR RECORDING
    (window as any).hj('event', 'start_hotjar_rec');
    console.log("START-HOTJAR-REC");
    sendWebsiteLivestreamMessage('[HOTJAR REC - UX] STARTED - REFERRAL COUPON SCAN');

    if (userSub) {
      dispatch(submitCouponRequest('AJAKTEMEN-' + userSub)); // Coupon request. Should open overlay referral upon returning.
    }
  }, [userSub]);

  console.log(userSub);
  console.log(appliedCoupon?.couponKey);

  if (!userSub || !appliedCoupon?.couponKey?.startsWith("AJAKTEMEN")) {
    return (
    <Box style={{
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress/>
    </Box>
    );
  }

  // 03. If the user is signed in and has purchased, we open coupon overlay and redirect to shop page
  // Currently the coupon use time is set as 8 days
  if (appliedCoupon?.couponKey?.startsWith("AJAKTEMEN") && new Date().toISOString() < appliedCoupon?.expirationDateTime) {
    dispatch(openOverlayRepurchase());
  }

  return (<Redirect to={"/shop"} />);
}
import { Divider, Link, Typography, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import React from 'react';
import { Link as RouterLink, Redirect, useHistory } from "react-router-dom";
import { Mixpanel } from '../mixpanel';
import { categoryMap } from 'common-ts/dist/models/CategoryList';
import { resetAddressDraft } from '../redux/AddressRedux';
import { getCourierOptions, calculateCompleteCartInvoice, getDiscountedPriceAmountWithPromotionsAndShipment, getNumberOfItems, resetCartAddressAndPaymentState, submitOrderRequest, updateUserTrigger } from '../redux/CartRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import CartContent from './CartContent';
import { FareastButton } from './OverlayComponentButton';
import PageCheckoutAddress from './PageCheckoutAddress';
import PageCheckoutOrderRejected from './PageCheckoutOrderRejected';
import PageCheckoutPayment from './PageCheckoutPayment';
import PageCheckoutPaymentFailure from './PageCheckoutPaymentFailure';
import PageCheckoutPaymentSuccess from './PageCheckoutPaymentSuccess';
import { sendWebsiteEventMessage } from '../telegrambotevents';
import Countdown from 'react-countdown';
import PageCheckoutCourier from './PageCheckoutCourier';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  fullHeight: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '100px',
    },
  },
  titleLogoContainer: {
    height: '42px',
    marginTop: '34px',
    marginBottom: '10px',
  },
  titleLogo: {
    height: '24px',
    display: 'block', // We use block so that the img is unique horizontally in the div
    marginLeft: '40px',
  },
  checkoutFormContainer: {
    height: 'auto',
    width: '100%',
  },
  sideCartList: {
    display: 'none',
    height: '100vh',
    background: '#fafafa',
    width: '480px',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  sideCartListInner: {
    background: '#fafafa',
    width: '432px',
    padding: '24px',
  },
  cartMainSegment: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 480px)',
    },
  },
  cartMobile: {
    width: 'calc(100%-48px)',
    padding: '16px 24px 26px 24px',
    backgroundColor: '#fafafa',
  },
  cartMobileAccordion: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '18px',
    backgroundColor: '#fafafa',
    '&:active': {
      backgroundColor: '#eaeaea',
    }
  },
  cartMobileAccordionIcon: {
    marginRight: '12px',
    color: '#222',
  },
  cartMobileAccordionText: {
    fontSize: '13px',
    fontWeight: 400,
    color: '#222',
  },
  cartMobileAccordionIconOpenClose: {
    marginLeft: '4px',
    color: '#222',
  },
  cartMobileAccordionPrice: {
    color: '#222',
    fontSize: '14px',
    fontFamily: 'Open Sans, sans-serif',
  },
  cartMobileText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cartMobileContainer: {
    width: '100%',
  },
  nextStepButtonContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginTop: '16px',
  },
  nextStepButton: {
    marginRight: '20px',
    marginLeft: 'auto',
  },
  countdownComponent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  countdownMainText: {
    fontSize: "20px",
    fontWeight: 500,
    paddingTop: "8px",
    paddingBottom: "10px",
  },
  countdownMainTextTimeout: {
    fontSize: "24px",
    fontWeight: 500,
    paddingTop: "8px",
    paddingBottom: "16px",
  },
  countdownNumberText: {
    fontSize: "22px",
    fontWeight: 300,
    letterSpacing: "1.2px",
    fontFamily: 'Open Sans, sans-serif',
    paddingBottom: "22px",
    color: "#343434",
  },
  countdownSubTextHighlight: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#000000",
  },
  countdownSubText: {
    fontSize: "12px",
    fontWeight: 300,
    color: "#343434",
    paddingBottom: "42px",
    textAlign: "center",
  },
  countdownSubTextTimeout: {
    fontSize: "12px",
    fontWeight: 300,
    color: "#343434",
    paddingBottom: "4px",
    paddingTop: "4px",
    textAlign: "center",
  },
  countdownButtonText: {
    fontSize: "12px",
    letterSpacing: "0.7px",
    fontWeight: 500,
  },
}),
);

export default function PageCheckout() {
  const [checkoutStep, setCheckoutStep] = React.useState("address");
  const [cartSegmentOpen, setCartSegmentOpen] = React.useState<boolean>(false);
  const [expirePaymentTime, setExpirePaymentTime] = React.useState<Date>(new Date());
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const cart = useAppSelector((state) => state.cart);
  const productsList = useAppSelector((state) => state.productsList);
  const isLoggedIn = useAppSelector((state) => state.account.authState === 'signedin');
  const userTriggerCheckout = useAppSelector((state) => state.cart.userTriggerCheckout);
  const currentAddress = useAppSelector(state => state.addressList.addressDraft);
  const userBodyMeasurementsDict = useAppSelector(state => state.userMeasurementsList.userBodyMeasurementsDictionary);
  const totalPayment = useAppSelector(state => getDiscountedPriceAmountWithPromotionsAndShipment(state.cart, state.productsList, state.userMeasurementsList.userBodyMeasurementsDictionary));

  const [currentlyMakingOrder, setCurrentlyMakingOrder] = React.useState(false);

  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("md"));
  const history = useHistory();

  if (userTriggerCheckout) {
    dispatch(updateUserTrigger(false));
    dispatch(resetCartAddressAndPaymentState());
    dispatch(resetAddressDraft());
  }

  // paymentStep influences this UI component's state, since this payment step
  // is something out of control of the UI / is not trigerred by the UI.
  // The UI component monitors and responds to the outside payment actions.
  // TODO Pull paymentStep to redux level.
  const paymentStep = cart.orderStatus;
  if ((paymentStep === "success" && checkoutStep !== "success")
    || (paymentStep === "paymentFailed" && checkoutStep !== "paymentFailed")) {
    setCheckoutStep(paymentStep);
  } else if (paymentStep === "waitingForPayment" && checkoutStep !== "payment") {
    setCheckoutStep("payment");
  } else if (paymentStep === "rejected" && checkoutStep !== "rejected") {
    setCheckoutStep("rejected");
  }

  const checkoutStage: {[key:string]: any} = {
    "address": {
      activeStep: 0,
    },
    "courier": {
      activeStep: 0,
    },
    "payment": {
      activeStep: 1,
    },
    "success": {
      activeStep: 2,
    },
    "paymentFailed": {
      activeStep: 2,
    }, 
    "rejected": {
      activeStep: 2,
    }
  }

  const activeStep = checkoutStage[checkoutStep].activeStep;

  // If there are no items in the cart, and the process is not yet done,
  // we redirect the user to "HOME"
  if ((activeStep !== 2 && getNumberOfItems(cart) < 1) || !isLoggedIn) {
    return (
      <Redirect to={categoryMap["HOME"]} />
    )
  }

  let children: JSX.Element[] = [];
  switch(checkoutStep) {
    case "address":
      children.push(
        <PageCheckoutAddress checkoutStep={checkoutStep} setCheckoutStep={setCheckoutStep} isDisabled={false} /> 
      );
      if (currentAddress.creationDateTime) {
        children.push(
          <Box className={classes.nextStepButtonContainer}>
            <FareastButton onClick={() => {
              // Fetch courier options
              if (currentAddress?.destinationKiriminAjaObject?.kecamatan_id) {
                const weight = Math.max(1000, 250 * getNumberOfItems(cart));
                dispatch(getCourierOptions({ 
                  dest: currentAddress?.destinationKiriminAjaObject?.kecamatan_id,
                  weight: weight,
                }));
                sendWebsiteEventMessage("[CHECKOUT] Address entered, fetching courier options.");

                setCheckoutStep("courier");
              }
            }}
              disabled={!currentAddress.destinationKiriminAjaObject?.kecamatan_id || !currentAddress.kecamatan} buttonMarginLeftRight="20px"
              buttonMarginTopBottom="0px">
              <b>NEXT</b>
            </FareastButton>
          </Box>
        )
      }
      break;
    case "courier":
      children.push(
        <PageCheckoutCourier checkoutStep={checkoutStep} setCheckoutStep={setCheckoutStep} isDisabled={false} /> 
      );
      children.push(
        <Box className={classes.nextStepButtonContainer}>
          <FareastButton onClick={() => {
            sendWebsiteEventMessage("[CHECKOUT] Address entered and courier selected, continuing to payment.");
            dispatch(submitOrderRequest());

            sendWebsiteEventMessage("[CHECKOUT] Payment for Rp " + totalPayment + " initiated.");

            const currentDateTime = new Date();
            currentDateTime.setTime(currentDateTime.getTime() + (60 * 60 * 1000));
            setExpirePaymentTime(currentDateTime);

            setCurrentlyMakingOrder(true);
            setTimeout(() => {
              setCurrentlyMakingOrder(false);
            }, 5000); // Give 5 s delay before each order creation
          }}
            disabled={!cart.courierSelectionObject || !cart.shipmentPrice || currentlyMakingOrder} buttonMarginLeftRight="20px"
            buttonMarginTopBottom="6px">
            <b>NEXT</b>
          </FareastButton>
        </Box>
      )
      break;
    case "payment":
      children.push(
        <PageCheckoutPayment checkoutStep={checkoutStep} setCheckoutStep={setCheckoutStep} /> 
      );
      break;
    case "success":
      children.push(
        <PageCheckoutPaymentSuccess checkoutStep={checkoutStep} setCheckoutStep={setCheckoutStep} /> 
      );
      break;
    case "paymentFailed":
      children.push(
        <PageCheckoutPaymentFailure checkoutStep={checkoutStep} setCheckoutStep={setCheckoutStep} /> 
      );
      break;
    case "rejected":
      children.push(
        <PageCheckoutOrderRejected checkoutStep={checkoutStep} setCheckoutStep={setCheckoutStep} rejectionMessage={cart.orderData} /> 
      );
      break;
  }

  const countdownRenderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return (
      <Box className={classes.countdownComponent}>
        <Typography className={classes.countdownMainTextTimeout} >Order Telah Dibatalkan</Typography>
        <Typography className={classes.countdownSubTextTimeout} 
        >Stok <span className={classes.countdownSubTextHighlight}>telah kami kembalikan</span> ke sistem.
        <br/>Buat order baru untuk membeli produk.</Typography>
        <FareastButton
          aria-label="balik"
          onClick={() => {
            history.push("/shop");
          }}
          buttonMarginLeftRight='0px'
          buttonMarginTopBottom='20px'
          style={{
            marginBottom: "48px",
          }}
        >
          <Typography className={classes.countdownButtonText}>
            LIHAT SEMUA PRODUK
          </Typography>
        </FareastButton>
      </Box>);
    } else {
      // Render a countdown
      return (
      <Box className={classes.countdownComponent}>
        <Typography className={classes.countdownMainText} >Selesaikan Pembayaran</Typography>
        <span className={classes.countdownNumberText}>{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</span>
        <Typography className={classes.countdownSubText} 
        >Kami <span className={classes.countdownSubTextHighlight}>menjaga stok pesanan kamu</span>
        <br/>selama waktu pembayaran</Typography>
      </Box>)
    }
  };

  return (
    <Box className={classes.fullHeight}>
      <Box className={classes.cartMainSegment}>
        <Box justifyContent="center" className={classes.titleLogoContainer} >
          <Link component={RouterLink} to={categoryMap["HOME"]} onClick={() => {
            Mixpanel.track('navigateRoute', {
              'routePath': '/',
              'routeSource': 'checkOut',
            });
            Mixpanel.track('checkoutExit', {
              'routeSource': 'checkOut',
            });
          }}>
            <img className={classes.titleLogo} src={process.env.PUBLIC_URL + '/studios-wordmark-final.svg'} alt="maja logo"/>
          </Link>
        </Box>
        <Box className={classes.checkoutFormContainer}>
          {
            checkoutStep === "payment" ?
            <Countdown
              date={expirePaymentTime}
              renderer={countdownRenderer}
              onComplete={(timeDelta, completedOnStart) => {
              }}
            />:
            null
          }
          {
            isMdOrUp ?
            null :
            <Box className={classes.cartMobileContainer}>
              <Divider/>
              <Box className={classes.cartMobileAccordion} onClick={() => setCartSegmentOpen(!cartSegmentOpen)}>
                <Box className={classes.cartMobileText}>
                  <ShoppingCartOutlinedIcon className={classes.cartMobileAccordionIcon} />
                  <Typography className={classes.cartMobileAccordionText}>
                    {
                      cartSegmentOpen ?
                      "Tutup Detail Pembelian" :
                      "Buka Detail Pembelian"
                    }
                  </Typography>
                  {
                    cartSegmentOpen ?
                    <ExpandLess fontSize="small" className={classes.cartMobileAccordionIconOpenClose}/> :
                    <ExpandMore fontSize="small" className={classes.cartMobileAccordionIconOpenClose}/>
                  }
                </Box>
                <Box>
                  <Typography className={classes.cartMobileAccordionPrice}>
                    {"Rp " + getDiscountedPriceAmountWithPromotionsAndShipment(cart, productsList, userBodyMeasurementsDict).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
                </Box>
              </Box>
              <Divider/>
              <Collapse in={cartSegmentOpen}>
                <Box className={classes.cartMobile}>
                  <CartContent cart={cart} productsList={productsList}
                    isModifiable={false} 
                    withPromoField={false}/>
                </Box> 
                <Divider/>
              </Collapse>
            </Box>
          }
          {children}
        </Box>
      </Box>
      <Box className={classes.sideCartList} >
        <Box className={classes.sideCartListInner}>
          <CartContent cart={cart} productsList={productsList} 
            isModifiable={false} 
            withPromoField={false} />
        </Box>
      </Box>
    </Box>
  );
}
import { Box, CircularProgress } from '@material-ui/core';
import {
  useParams, Redirect,
} from "react-router-dom";
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { signIn } from '../redux/AccountRedux';
import { openOverlay as openOverlayRepurchase } from '../redux/UIOverlayQRRepurchaseRedux';
import { sendWebsiteLivestreamMessage } from '../telegrambotevents';
import { setLocalCoupon } from '../redux/CartRedux';

export default function PageCreatePersonalSize() {
  let { userSub } = useParams<{ userSub: string }>();

  const userAcc = useAppSelector(state => state.account);
  const isSignedIn = useAppSelector(state => state.account.authState === 'signedin');
  const dispatch = useAppDispatch();

  // 01. Auto sign in on non-trivial userSub
  useEffect(() => {

    // START HOTJAR RECORDING
    (window as any).hj('event', 'start_hotjar_rec');
    console.log("START-HOTJAR-REC");
    sendWebsiteLivestreamMessage('[HOTJAR REC - UX] STARTED - REPURCHASE COUPON SCAN');

    if (userSub && (!isSignedIn || userAcc.sub !== userSub)) {
      dispatch(signIn({ password: '123', useEmail: true, otpValue: '', subTempPass: 'skfajsakjf', userSub: userSub}));
    }
  }, [userSub]);

  // 02. We wait until sign in is completed
  if (!isSignedIn || userAcc.sub !== userSub) {
    return (
    <Box style={{
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress/>
    </Box>
    );
  }

  const expirationDateTime = new Date(new Date(userAcc.firstOrderTime).getTime() + 8 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10) + 'T16:59:00.000Z';

  // 03. If the user is signed in and has purchased, we open coupon overlay and redirect to shop page
  // Currently the coupon use time is set as 8 days
  if (userAcc.paidOrderCount === 1 && isSignedIn && new Date().toISOString() < expirationDateTime) {
    // Open overlay. Local coupon should already be automatically applied if the user is signed in.
    dispatch(setLocalCoupon({
      couponKey: 'REPURCHASE20',
      coupon: {
        "couponKey": "REPURCHASE20",
        "allowedUsers": [userAcc.sub],
        "couponConditions": [],
        "couponEffects": [
         {
          "effectKey": "SINGLEITEMDISCOUNT",
          "effectValue": 0.2
         }
        ],
        "expirationDateTime": expirationDateTime,
        "maxRedeems": 1
      },
    }));
    dispatch(openOverlayRepurchase());
  }

  return (<Redirect to={"/shop"} />);
}